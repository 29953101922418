import React from 'react'

function Policy() {
    return (
        <div class="policy">
           <h2>Privacy Policy</h2>

<p>
At Infinytics, we value your privacy and are committed to protecting the personal information that you share with us. This Privacy Policy explains how we collect, use, and safeguard your information when you use our services. By using our services, you agree to the practices described in this policy.
</p>

<h2>1. Information We Collect</h2>

<p>
We may collect the following types of personal information:
</p>
<ul>
    <li><strong>Personal Identification Information:</strong> Name, email address, phone number, and job title.</li>
    <li><strong>Business Information:</strong> Company name, address, industry, and other business-related details.</li>
    <li><strong>Technical Data:</strong> IP addresses, browser type, device type, and operating system used to access our services.</li>
    <li><strong>Cookies and Tracking Technologies:</strong> Information on how you interact with our website or services, collected through cookies and similar technologies.</li>
</ul>

<h2>2. How We Use Your Information</h2>

<p>
We use the information we collect to:
</p>
<ul>
    <li>Provide and improve our services.</li>
    <li>Personalize your experience and tailor our services to your preferences.</li>
    <li>Communicate with you about project updates, new services, and promotions.</li>
    <li>Fulfill legal obligations and protect our rights or the rights of others.</li>
    <li>Conduct analytics to better understand how our services are used.</li>
</ul>

<h2>3. Sharing Your Information</h2>

<p>
We do not sell or rent your personal information to third parties. We may share your information in the following circumstances:
</p>
<ul>
    <li>With trusted service providers who assist us in operating our business or providing services to you (e.g., cloud hosting providers, payment processors).</li>
    <li>When required by law, such as to comply with a subpoena, court order, or similar legal process.</li>
    <li>In connection with a business transaction, such as a merger, acquisition, or sale of assets, where personal information may be transferred as part of the business assets.</li>
</ul>

<h2>4. Data Security</h2>

<p>
We implement a variety of security measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is 100% secure. Therefore, while we strive to protect your personal information, we cannot guarantee its absolute security.
</p>

<h2>5. Data Retention</h2>

<p>
We will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy, or as required by law.
</p>

<h2>6. Your Rights</h2>

<p>
You have the following rights regarding your personal information:
</p>
<ul>
    <li>Access your personal information and request a copy of it.</li>
    <li>Correct any inaccurate or incomplete information.</li>
    <li>Request the deletion of your personal information in certain circumstances.</li>
    <li>Restrict or object to the processing of your personal information.</li>
</ul>

<p>
To exercise any of these rights, please contact us at [Your Contact Information].
</p>

<h2>7. Cookies and Tracking Technologies</h2>

<p>
We use cookies and similar tracking technologies to enhance your experience on our website. Cookies are small text files that are placed on your device to store data that can be recalled by a web server in the domain that placed the cookie. You can control the use of cookies through your browser settings, but disabling cookies may limit your ability to use some features of our website.
</p>

<h2>8. Third-Party Links</h2>

<p>
Our website may contain links to third-party websites. We are not responsible for the privacy practices or the content of these external sites. We encourage you to review the privacy policies of any third-party websites you visit.
</p>

<h2>9. Changes to this Privacy Policy</h2>

<p>
We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. Any changes will be posted on this page, and we will update the "Last Updated" date at the top of this policy.
</p>

</div>

    )
}

export default Policy
