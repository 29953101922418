import React from 'react'

function TermsCondition() {
    return (
        <div class="policy">
           <h2>Terms and Conditions</h2>

<h2>1. Agreement to Terms</h2>
<p>
By engaging Infinytics we for software development services, you agree to these Terms and Conditions. These terms apply to all services provided by us unless specifically overridden by a written agreement.
</p>

<h2>2. Services</h2>
<p>
We agree to provide software development services as described in the Project Proposal or Scope of Work document, which will outline the deliverables, timelines, and payment terms. Any additional services requested by the Client that are not covered in the original agreement will be subject to additional fees.
</p>

<h2>3. Project Timeline and Milestones</h2>
<p>
All project timelines and delivery dates are estimates unless otherwise agreed in writing. We will make reasonable efforts to meet the agreed-upon deadlines. However, delays caused by the Client, including but not limited to delayed feedback, additional requests, or lack of resources, may result in the extension of timelines.
</p>

<h2>4. Client Responsibilities</h2>
<p>
The Client agrees to provide all necessary information, resources, and feedback required for the successful completion of the project. Failure to provide required materials or feedback in a timely manner may result in delays for which we will not be responsible.
</p>

<h2>5. Payment Terms</h2>
<p>
Fees for services will be detailed in the Project Proposal or Scope of Work. The Client agrees to make payments according to the agreed-upon schedule. Late payments may be subject to interest or penalties. We reserve the right to suspend work if payments are not made on time.
</p>

<h2>6. Intellectual Property Rights</h2>
<p>
Upon full payment of the project fees, the Client will have ownership of the software deliverables and associated intellectual property rights. We retain ownership of any pre-existing proprietary code, libraries, or tools used during the project unless otherwise agreed in writing.
</p>

<h2>7. Confidentiality</h2>
<p>
Both parties agree to maintain the confidentiality of any proprietary information exchanged during the course of the project. This includes technical details, client information, business plans, and any other sensitive data.
</p>

<h2>8. Warranty and Support</h2>
<p>
We will make reasonable efforts to ensure that the software functions as specified in the Project Proposal. However, we do not guarantee that the software will be free from bugs or errors. After the delivery of the project, we may provide a limited warranty period for bug fixes or support, which will be detailed in the agreement. Ongoing maintenance or support will require a separate contract or retainer.
</p>

<h2>9. Limitations of Liability</h2>
<p>
We will not be liable for any indirect, incidental, or consequential damages arising from the use or inability to use the software, including loss of profits, data, or other intangible losses. Our liability will be limited to the total amount paid by the Client for the specific project.
</p>

<h2>10. Termination</h2>
<p>
Either party may terminate the agreement if the other party breaches any material term and fails to remedy the breach within 14 days of written notice. Upon termination, the Client will be responsible for paying for any completed work and any other outstanding fees.
</p>

<h2>11. Amendments</h2>
<p>
We reserve the right to modify these Terms and Conditions at any time. The Client will be notified of any changes, and continued use of our services following such modifications will constitute acceptance of the revised terms.
</p>

<h2>12. Governing Law</h2>
<p>
These terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising from this agreement will be subject to the exclusive jurisdiction of the courts in [Your Jurisdiction].
</p>

<h2>13. Entire Agreement</h2>
<p>
These Terms and Conditions, along with the Project Proposal, represent the entire agreement between the parties and supersede any prior agreements or understandings, whether written or oral.
</p>
        </div>

    )
}

export default TermsCondition
