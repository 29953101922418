import React from 'react'

import Header from './header';
import HomePage from './homepage';
import About from './about';
import Services from './services';
import Portfolio from './portfolio';
import Footer from './footer';
export default function Home() {
    return (
        <div>
            <Header />
            <HomePage />
            <About />
            <Services />
            <Portfolio />
            <Footer />
        </div>
    )
}
